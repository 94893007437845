<script setup lang="ts">
  import { useScreenSize } from '~/shared/lib'

  const { isTablet } = storeToRefs(useScreenSize())
  const snowFlakesCount = ref(5)
  const addSnowflakesInterval = ref()
  const addSnowflakes = () => {
    if (snowFlakesCount.value < 50) {
      snowFlakesCount.value += 1
    } else if (addSnowflakesInterval.value) {
      clearInterval(addSnowflakesInterval.value)
    }
  }
  onMounted(() => {
    addSnowflakesInterval.value = setInterval(addSnowflakes, 500)
  })
  const parts = computed(() => {
    if (!isTablet.value) {
      return ['left', 'right']
    }
    return ['left']
  })
</script>

<template>
  <div
    v-for="snowflakes in parts"
    :class="['snowflakes', snowflakes]"
  >
    <div
      v-for="item in snowFlakesCount"
      :key="item"
      class="snowflake"
    />
  </div>
</template>

<style scoped lang="scss">
  $snowflake-size: 20px;

  @mixin generate-snowflakes($count, $left: false, $right: false) {
    @for $i from 1 through $count {
      $animation-duration: random() * 4 + 8s;
      $image-number: $i % 10 + 1;

      .snowflake:nth-child(#{$i}) {
        @if $left {
          left: random() * 50%;
          @include mobile {
            left: random() * 100%;
          }
        }
        @if $right {
          right: random() * 50%;
        }
        animation-duration: $animation-duration;
        transform: rotate(random(360) * 1deg);
        background-image: url('~/assets/svg/snow/snow_#{$image-number}.svg');
      }
    }
  }

  .snowflakes {
    position: absolute;
    z-index: 1;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    overflow: hidden;

    &.left {
      @include generate-snowflakes(50, $left: true);
      left: 0;
    }

    &.right {
      @include generate-snowflakes(200, $right: true);
      right: 0;
    }

    .snowflake {
      position: absolute;
      width: $snowflake-size;
      height: $snowflake-size;
      background-size: cover;
      animation: snowfall linear infinite;
    }
  }

  @keyframes snowfall {
    from {
      transform: translateY(0) rotate(0);
    }
    to {
      //make rotate randomly
      transform: translateY(100vh) rotate(360deg);
    }
  }
</style>
