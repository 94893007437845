import revive_payload_client_Zm8IgEalmj from "/app/node_modules/.pnpm/nuxt@3.12.3_@types+node@20.14.10_eslint@8.52.0_rollup@4.18.1_sass@1.77.6_terser@5.31.1_typesc_26jyl63frjrgiljq3kpldf64d4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_lgIqRS44oR from "/app/node_modules/.pnpm/nuxt@3.12.3_@types+node@20.14.10_eslint@8.52.0_rollup@4.18.1_sass@1.77.6_terser@5.31.1_typesc_26jyl63frjrgiljq3kpldf64d4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_A4eK3qPFAA from "/app/node_modules/.pnpm/nuxt@3.12.3_@types+node@20.14.10_eslint@8.52.0_rollup@4.18.1_sass@1.77.6_terser@5.31.1_typesc_26jyl63frjrgiljq3kpldf64d4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_0VcRSxJcxR from "/app/node_modules/.pnpm/nuxt@3.12.3_@types+node@20.14.10_eslint@8.52.0_rollup@4.18.1_sass@1.77.6_terser@5.31.1_typesc_26jyl63frjrgiljq3kpldf64d4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_pkYNciM2Lg from "/app/node_modules/.pnpm/nuxt@3.12.3_@types+node@20.14.10_eslint@8.52.0_rollup@4.18.1_sass@1.77.6_terser@5.31.1_typesc_26jyl63frjrgiljq3kpldf64d4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_uPv3H3udky from "/app/node_modules/.pnpm/nuxt@3.12.3_@types+node@20.14.10_eslint@8.52.0_rollup@4.18.1_sass@1.77.6_terser@5.31.1_typesc_26jyl63frjrgiljq3kpldf64d4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_9MFOVgTYU4 from "/app/node_modules/.pnpm/nuxt@3.12.3_@types+node@20.14.10_eslint@8.52.0_rollup@4.18.1_sass@1.77.6_terser@5.31.1_typesc_26jyl63frjrgiljq3kpldf64d4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_FfAN1nlUNB from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.18.1_typescript@5.5.3_vue@3.4.31/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_MxyFBhOXjA from "/app/node_modules/.pnpm/nuxt@3.12.3_@types+node@20.14.10_eslint@8.52.0_rollup@4.18.1_sass@1.77.6_terser@5.31.1_typesc_26jyl63frjrgiljq3kpldf64d4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_x3zNa5vUA4 from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_rollup@4.18.1_vue@3.4.31/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_iw9PoOhm45 from "/app/node_modules/.pnpm/dayjs-nuxt@2.1.9_rollup@4.18.1/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import plugin_szrZD8fzMA from "/app/node_modules/.pnpm/nuxt-quasar-ui@2.1.3_@quasar+extras@1.16.12_quasar@2.16.5_rollup@4.18.1/node_modules/nuxt-quasar-ui/dist/runtime/plugin.js";
import plugin_client_7MGZvT1HbE from "/app/node_modules/.pnpm/nuxt-gtag@3.0.2_rollup@4.18.1/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import sentry_client_shVUlIjFLk from "/app/plugins/sentry.client.ts";
import vue_query_wrmMkNpEpe from "/app/plugins/vue-query.ts";
export default [
  revive_payload_client_Zm8IgEalmj,
  unhead_lgIqRS44oR,
  router_A4eK3qPFAA,
  payload_client_0VcRSxJcxR,
  navigation_repaint_client_pkYNciM2Lg,
  check_outdated_build_client_uPv3H3udky,
  chunk_reload_client_9MFOVgTYU4,
  plugin_vue3_FfAN1nlUNB,
  components_plugin_KR1HBZs4kY,
  prefetch_client_MxyFBhOXjA,
  i18n_x3zNa5vUA4,
  plugin_iw9PoOhm45,
  plugin_szrZD8fzMA,
  plugin_client_7MGZvT1HbE,
  sentry_client_shVUlIjFLk,
  vue_query_wrmMkNpEpe
]