<script setup lang="ts">
  import { useUplatformLocale } from '~/features/locale'

  import { useScreenSize } from './shared/lib'
  import { TheSnowflakes } from './widgets/layout'
  const { updateLocale } = useUplatformLocale()

  updateLocale()

  const { isTablet } = storeToRefs(useScreenSize())
</script>
<template>
  <div class="app-container">
    <TheSnowflakes v-if="!isTablet" />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>
<style scoped lang="scss">
  .app-container {
    background-color: $grey;
  }
</style>

<style lang="scss">
  @include disableQuasarFormatting;
  .app-container {
    position: relative;
  }
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scrollbar-gutter: stable;
  }
  body {
    background-color: $grey;
  }
</style>
